.App {
  // height: 100vh;
  // width: 100vw;
  // overflow-x: hidden;

  a {
    text-decoration: none;
  }
  .sections {
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
    position: relative;
    scrollbar-width: none;
    // -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }

    > * {
      scroll-snap-align: start;
      // width: 100vw;
    }
  }

}
