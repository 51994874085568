@import "../../global.scss";

.navbar {
  background-color: transparent;
  width: 100%;
  height: 65px;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: fixed;
  top: 0;
  z-index: 100;
  background-color: $mainLightColor;
  box-shadow: 1px 1px 4px $mainColor;

  &.active {
    background-color: white;
    opacity: 0.9;
    box-shadow: 1px 1px 4px $mainColor;
  }
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6vw;
  max-height: 75px;
  max-width: 1500px;

  p {
    font-family: $secondaryFont;
    font-weight: bold;
    font-size: 30px;
    color: $secondaryColor;
  }
}
.navbar-logo {
  color: #fff;
  margin-left: 20px;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  position: relative;

  @include mobile {
    margin-left: -45px;
  }
  img {
    height: 50px;
    width: 50px;
  }

  p {
    margin-left: 10px;
    // position: absolute;
    white-space: nowrap;
  }
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  // margin-right: 2rem;
  top: 65px;

  .nav-item {
    height: 65px;

    .nav-links {
      cursor: pointer;
      color: black;
      display: flex;
      align-items: center;
      text-decoration: none;
      font-size: $mainSize;
      padding: 0.5rem 1rem;
      font-family: $mainFont;
      // font-weight: bold;

      @include mobile {
        color: white;
      }
      @media screen and (orientation: portrait) {
        color: white;
      }
      &:hover {
        text-decoration: underline $mainColor;
        color: $mainColor;
        @include mobile {
          text-decoration: underline #ccb3bb;
        }
      }

      &.active {
        color: $mainColor;
        @include mobile {
          color: #ccb3bb;
        }
        @media screen and (orientation: portrait) {
          color: #ccb3bb;
        }
        // text-decoration: underline $mainColor;
        // border-bottom: 1px solid $mainColor;
      }
    }
  }
}

.fa-bars {
  color: $secondaryColor;
}
.nav-links-mobile {
  display: none;
}
.menu-icon {
  display: none;
}

@include mobile {
  .NavbarItems {
    position: relative;
  }
  .navbar {
    background-color: white;
    opacity: 0.9;
    height: 20vw;
    max-height: 65px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15vw;
    position: fixed;
    top: 0;
    z-index: 999;
  }
  .logoimg {
    position: relative;
    height: auto;
    width: auto;
    display: block;
    max-height: 50px;
    top: 0;
    left: 0;
    transform: translate(-300%, 0%);
  }
  .nav-menu {
    height: calc(100vh - 65px);
    width: 300px;
    background-color: #737373;
    position: fixed;
    right: -300px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 1;
    transition: all 1s ease;
  }
  .nav-menu.active {
    right: 0;
    transition: all 1s ease;
    z-index: 1;
    height: calc(100vh - min(65px, 20vw));
  }
  .nav-item {
    height: 20vw;
    max-height: 75px;
    align-items: center;
  }
  .nav-links {
    text-align: center;
    align-items: center;
    width: 300px;
    display: table;
    height: 10vw;
    color: white;
    font-family: $mainFont;
    &:hover {
      background-color: #fff;
      border-radius: 0;
    }
  }
  .menu-icon {
    display: block;
    position: absolute;
    top: 10px;
    right: -10px;
    transform: translate(-100%, 30%);
    font-size: 1.8rem;
    cursor: pointer;

    .hamburger {
      width: 32px;
      height: 25px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;

      span {
        width: 100%;
        height: 3px;
        background-color: $mainColor;
        transform-origin: left;
        transition: all 1s ease;
      }

      &.active {
        span {
          &:first-child {
            background-color: $mainColor;
            transform: rotate(45deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:last-child {
            background-color: $mainColor;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
  .fa-times {
    color: $secondaryColor;
    font-size: 2rem;
    display: flex;
  }
  .nav-links-mobile {
    display: block;
    text-align: center;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 50vw;
    // background-color: #242424;
    // color: #fff;
    transition: all 1s ease-out;
    &:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }
  }
}

@media screen and (orientation: portrait) {
  .NavbarItems {
    position: relative;
  }
  .navbar {
    background-color: white;
    opacity: 0.9;
    height: 20vw;
    max-height: 65px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15vw;
    position: fixed;
    top: 0;
    z-index: 999;
  }
  .logoimg {
    position: relative;
    height: auto;
    width: auto;
    display: block;
    max-height: 50px;
    top: 0;
    left: 0;
    transform: translate(-300%, 0%);
  }
  .nav-menu {
    height: calc(100vh - min(60px, 20vw));
    width: 300px;
    background-color: #737373;
    position: fixed;
    right: -200%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 1;
    transition: all 1s ease;
  }
  .nav-menu.active {
    right: 0;
    transition: all 1s ease;
    z-index: 1;
    height: calc(100vh - 65px);
    padding-bottom: 50px;
  }
  .nav-item {
    height: 20vw;
    max-height: 75px;
    align-items: center;
  }
  .nav-links {
    text-align: center;
    align-items: center;
    display: table;
    margin-top: 30px;
    color: white;
    font-family: $mainFont;
    &:hover {
      background-color: #fff;
      border-radius: 0;
    }
  }
  .menu-icon {
    display: block;
    position: absolute;
    top: 10px;
    right: -10px;
    transform: translate(-100%, 30%);
    font-size: 1.8rem;
    cursor: pointer;

    .hamburger {
      width: 32px;
      height: 25px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;

      span {
        width: 100%;
        height: 3px;
        background-color: $mainColor;
        transform-origin: left;
        transition: all 1s ease;
      }

      &.active {
        span {
          &:first-child {
            background-color: $mainColor;
            transform: rotate(45deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:last-child {
            background-color: $mainColor;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
  .fa-times {
    color: $secondaryColor;
    font-size: 2rem;
    display: flex;
  }
  .nav-links-mobile {
    display: block;
    text-align: center;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 50vw;
    // background-color: #242424;
    // color: #fff;
    transition: all 0.3s ease-out;
    &:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }
  }
}
