@import "../../global.scss";

.home {
  //   background-color: radial-gradient(at 0%0%, $offWhiteColor, red);
  // background: radial-gradient(circle at 8rem 14px, #e66465, #9198e5);
  background-color: $offWhiteColor;
  color: $offWhiteColor;
  display: flex;
  scroll-snap-align: start;
  width: 100%;

  @include mobile {
    flex-direction: column;
    padding-top: 65px;
    align-items: center;
    justify-content: center;
  }

  .left {
    flex: 0.4;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    flex-direction: column;

    padding-left: calc(40vw - min(25vw, 350px));
    padding-top: $mainPadding;
    padding-bottom: $mainPadding;
    text-align: left;

    @include mobile {
      align-items: center;
      justify-content: center;
      display: flex;
      padding: $mainPadding;
    }

    @media (max-height: 700px) {
      padding-top: calc(min(20px, 5vh));
      padding-bottom: calc(min(20px, 5vh));
    }

    .left-top {
      position: sticky;
      top: $mainPadding;
      right: 0;

      @media (max-height: 700px) {
        top: calc(min(20px, 5vh));
      }

      @include mobile {
        position: relative;
        top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .img-container {
        bottom: -2px;
        right: 3vw;
        width: 25vw;
        height: 25vw;
        max-height: 300px;
        max-width: 300px;

        background-color: $mainColor;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        z-index: 5;
        overflow: hidden;

        @include mobile {
          height: 80vw;
          width: 80vw;
          max-height: 400px;
          max-width: 400px;
          animation: none;
        }

        @media (max-height: 700px) {
          height: 30vh;
          width: 30vh;
          max-height: 300px;
          max-width: 300px;
        }

        img {
          width: 70%;
        }

        // animation: fade-down 0.7s ease-in backwards;
        @keyframes fade-down {
          0% {
            opacity: 0;
            transform: translateY(-20px);
          }
          100% {
            opacity: 1;
            transform: translateY(0px);
          }
        }
      }
    }
    .name {
      position: sticky;
      font-family: "Inter";
      font-weight: bold;
      font-size: 50px;
      color: $mainColor;
      margin-top: 20px;
      // animation: fade .3s ease-in;
      pointer-events: none;

      @include mobile {
        font-size: 40px;
        text-align: center;
        position: relative;
        animation: none;
      }

      @media (max-height: 700px) {
        font-size: 8vh;
      }

      @keyframes fade {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
    h2 {
      position: sticky;
      font-family: $mainFont;
      color: $secondaryColor;
      font-weight: normal;
      font-size: 30px;
      // animation: fade 0.4s 0.3s ease-in backwards;
      pointer-events: none;

      @include mobile {
        font-size: 20px;
        text-align: center;
        position: relative;
        animation: none;
      }

      @media (max-height: 700px) {
        font-size: calc(max(20px, 5vh));
      }

      @keyframes fade {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
    .nav {
      // border: 1px solid black;
      position: sticky;
      margin-top: 40px;
      animation-delay: 1s;

      @include mobile {
        position: relative;
      }

      @media (max-height: 700px) {
        margin-top: calc(min(20px, 5vh));
      }

      .nav-list {
        text-decoration: none;
        list-style-type: none;
        position: -webkit-sticky;
        position: sticky;
        top: 120px;
        display: inline-block;
        animation: fade 0.7s ease-in backwards;

        @include mobile {
          animation: none;
        }

        @keyframes fade {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        .nav-element {
          margin-bottom: 10px;

          .nav-links {
            cursor: pointer;
            color: $secondaryColor;
            font-family: "Manrope";
            font-weight: bold;
            text-decoration: none;
            font-size: $mainSize;

            &::before {
              display: inline-block;
              content: "";
              border-top: 1.5px solid $secondaryColor;
              width: 30px;
              transform: translateY(-5px);
              justify-content: center;
              margin-right: 5px;
              transition: all 0.7s ease;
            }

            &:hover {
              &::before {
                transition: all 0.7s ease;
                border-top: 1.5px solid $secondaryColor;
                width: 50px;
              }
            }

            &.active {
              color: $mainColor;
              &::before {
                transition: all 0.7s ease;
                border-top: 1.5px solid $mainColor;
                width: 50px;
              }
            }
          }
        }
      }
    }

    .links {
      display: flex;
      flex-direction: row;
      position: sticky;
      bottom: $mainPadding;
      // animation: fade-up 0.5s 0.7s ease-in backwards;

      @include mobile {
        margin-top: 60px;
        position: relative;
        animation: none;
      }

      @keyframes fade-up {
        0% {
          opacity: 0;
          transform: translateY(20px);
        }
        100% {
          opacity: 1;
          transform: translateY(0px);
        }
      }

      .link-wrapper {
        display: flex;
        align-items: center;
        padding-right: 15px;
        transition: 0.3s;
        a {
          text-decoration: none;

          .link {
            height: 6vh;
            width: 6vh;
            max-height: 40px;
            max-width: 40px;
            transition: 0.3s;
            color: #bababa;

            &:hover {
              transition: 0.3s;
              color: #b5909d;
            }
          }

          .link-smaller {
            height: 5vh;
            width: 5vh;
            max-height: 30px;
            max-width: 30px;
            color: #bababa;
            transition: 0.3s;

            &:hover {
              transition: 0.3s;
              color: #b5909d;
            }
          }
        }
      }
    }
  }

  .right {
    flex: 0.6;
    position: relative;
    padding-left: $mainPadding;
    // padding-top: $mainPadding;
    padding-right: calc(60vw - $contentWidth - 10vw);

    @media screen and (orientation: portrait) {
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    > * {
      padding-bottom: $mainPadding;
    }
    hr {
      padding-bottom: 0;
    }
  }
  .cursor {
    background: transparent;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 999;
  }
}
