@import url(https://fonts.googleapis.com/css?family=Manrope:regular,bold,italic);
@import url(https://fonts.googleapis.com/css?family=Montserrat:regular,bold,italic);
@import url(https://fonts.googleapis.com/css?family=Inter:regular,bold,italic);

$mainColor: #7f5463;
$secondaryColor: #797979;
$mainLightColor: #faf7f8;
$offWhiteColor: #f2f2f2;
$mainSize: 20px;
$mainFont: "Manrope";
$secondaryFont: "Inter";
$contentWidth: 575px;
$mainPadding: 5vw;

$mobileWidth: 942px;
@mixin mobile {
  @media (max-width: #{$mobileWidth}) {
    @content;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
